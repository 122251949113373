<template>
  <div class="register">
    <div class="contenido">
      <v-col cols="12" sm="8" md="6">
        <v-card class="card">
          <v-toolbar class="header" dark flat>
            <v-toolbar-title>Registro</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <validation-observer ref="cliente_form">
                <v-container>
                  <v-row dense>
                    <v-col
                      v-for="(item, index) in campos"
                      :key="index"
                      :cols="item.col.cols"
                      :md="item.col.md"
                    >
                      <validation-provider
                        v-if="item.required"
                        v-slot="{ errors }"
                        :name="item.values.label"
                        :rules="item.values.required"
                      >
                        <v-text-field
                          v-model="item.values.model"
                          v-if="item.type == 'field'"
                          :type="item.values.type"
                          :error-messages="errors"
                        >
                          <template v-slot:label>
                            <div class="tem-label">
                              {{ item.values.label }}
                            </div>
                          </template>
                        </v-text-field>
                      </validation-provider>
                      <div v-else>
                        <v-text-field
                          v-model="item.values.model"
                          v-if="item.type == 'field'"
                          :type="item.values.type"
                        >
                          <template v-slot:label>
                            <div class="tem-label">
                              {{ item.values.label }}
                            </div>
                          </template>
                        </v-text-field>
                        <v-menu
                          v-if="item.type == 'date'"
                          v-model="item.menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="item.values.model"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                              <template v-slot:label>
                                <div class="tem-label">
                                  {{ item.values.label }}
                                </div>
                              </template></v-text-field
                            >
                          </template>
                          <v-date-picker
                            v-model="item.values.model"
                            scrollable
                            first-day-of-week="1"
                            locale="es-co"
                            color="primary"
                            @input="item.menu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                        <v-select
                          v-if="item.type == 'select'"
                          v-model="item.values.model"
                          color="primary"
                          :items="item.values.generos"
                          item-color="primary"
                          :menu-props="{ maxHeight: '400' }"
                        >
                          <template v-slot:label>
                            <div class="tem-label">
                              {{ item.values.label }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" v-if="show">
                      <h3>Descargar para Android</h3>
                      <v-btn
                        fab
                        large
                        dark
                        color="green"
                        class="mt-4"
                        @click="
                          dowloadApp(
                            'https://play.google.com/store/apps/details?id=co.com.luxcultury&hl=es_CO&gl=US'
                          )
                        "
                      >
                        <v-icon>mdi-android</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" v-if="show">
                      <h3>Descargar para IOS</h3>
                      <v-btn
                        fab
                        large
                        dark
                        color="primary"
                        class="mt-4"
                        @click="
                          dowloadApp(
                            'https://apps.apple.com/co/app/luxcultury/id1458034498'
                          )
                        "
                      >
                        <v-icon>mdi-apple</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </validation-observer>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link :to="`/loginClientes/${$route.params.data}`">
                <v-btn text>Volver</v-btn>
              </router-link>
              <v-btn color="primary" @click="register()" :loading="loading"
                >Registrar</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </div>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ alert }}
      <v-btn
        text
        @click="
          snackbar = false;
          alert = '';
        "
        >X</v-btn
      >
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} es requerido",
});

extend("email", {
  ...email,
  message: "Email no valido",
});
import * as moment from "moment";
moment.locale("es");
export default {
  name: "registerCliente",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    show: false,
    loading: false,
    snackbar: false,
    color: "",
    alert: "",
    campos: [
      {
        col: { cols: 12, md: 6 },
        type: "field",
        required: true,
        values: {
          label: "ID / CC",
          model: "",
          type: "number",
          required: "required",
        },
        id: "cedula",
      },
      {
        col: { cols: 12, md: 6 },
        type: "field",
        required: true,
        values: {
          label: "Cliente",
          model: "",
          type: "text",
          required: "required",
        },
        id: "nombre",
      },
      {
        col: { cols: 12, md: 6 },
        type: "field",
        required: false,
        values: {
          label: "Correo",
          model: "",
          type: "text",
          required: "required",
        },
        id: "email",
      },
      {
        col: { cols: 12, md: 6 },
        type: "field",
        required: false,
        values: { label: "Direccion", model: "", type: "text" },
        id: "direccion",
      },
      {
        col: { cols: 12, md: 4 },
        type: "field",
        required: false,
        values: { label: "Teléfono", model: "", type: "number" },
        id: "telefono",
      },
      {
        col: { cols: 12, md: 4 },
        type: "date",
        required: false,
        values: { label: "Cumpleaños", model: moment().format("YYYY-MM-DD") },
        id: "fecha",
        menu: false,
      },
      {
        col: { cols: 12, md: 4 },
        type: "select",
        required: false,
        values: {
          label: "Género",
          model: "f",
          generos: [
            { text: "Masculino", value: "m" },
            { text: "Femenino", value: "f" },
            { text: "Otros", value: "o" },
          ],
        },
        id: "genero",
      },
    ],
  }),
  methods: {
    register() {
      this.$refs.cliente_form.validate().then((res) => {
        if (res) {
          this.loading = true;
          let body = this.campos.reduce((acum, item) => {
            acum[item.id] = item.values.model;
            return acum;
          }, {});

          body.telefono = body.telefono == "" ? "0000000" : body.telefono;
          body.direccion =
            body.direccion == "" ? "No registra" : body.direccion;
          body = Object.assign({ token: this.$route.params.data }, body);
          axios({
            method: "POST",
            url: "/register_cliente_link",
            data: JSON.stringify(body),
            responseType: "json",
          })
            .then((response) => {
              if (response.data.success) {
                this.show = true;
                this.color = "success";
                this.alert = response.data.data;
                this.snackbar = true;
                // setTimeout(() => {
                // this.$router.push("/loginClientes/" + this.$route.params.data);
                // }, 3500);
              }
            })
            .catch((error) => {
              this.color = "error";
              this.alert = error.response.data.data;
              this.snackbar = true;
            })
            .finally(() => (this.loading = false));
        }
      });
    },
    dowloadApp(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped>
.register {
  height: 100%;
  /* background-color: blanchedalmond; */
  background-image: url("https://i.imgur.com/P52bgCG.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.contenido {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5) !important;
  background: rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}

.header {
  background: rgba(0, 0, 0, 0.5) !important;
}

.tem-label {
  color: rgb(0, 0, 0);
}
</style>
