var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('div',{staticClass:"contenido"},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-card',{staticClass:"card"},[_c('v-toolbar',{staticClass:"header",attrs:{"dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Registro")])],1),_c('v-card-text',[_c('v-form',[_c('validation-observer',{ref:"cliente_form"},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_vm._l((_vm.campos),function(item,index){return _c('v-col',{key:index,attrs:{"cols":item.col.cols,"md":item.col.md}},[(item.required)?_c('validation-provider',{attrs:{"name":item.values.label,"rules":item.values.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(item.type == 'field')?_c('v-text-field',{attrs:{"type":item.values.type,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"tem-label"},[_vm._v(" "+_vm._s(item.values.label)+" ")])]},proxy:true}],null,true),model:{value:(item.values.model),callback:function ($$v) {_vm.$set(item.values, "model", $$v)},expression:"item.values.model"}}):_vm._e()]}}],null,true)}):_c('div',[(item.type == 'field')?_c('v-text-field',{attrs:{"type":item.values.type},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"tem-label"},[_vm._v(" "+_vm._s(item.values.label)+" ")])]},proxy:true}],null,true),model:{value:(item.values.model),callback:function ($$v) {_vm.$set(item.values, "model", $$v)},expression:"item.values.model"}}):_vm._e(),(item.type == 'date')?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"tem-label"},[_vm._v(" "+_vm._s(item.values.label)+" ")])]},proxy:true}],null,true),model:{value:(item.values.model),callback:function ($$v) {_vm.$set(item.values, "model", $$v)},expression:"item.values.model"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","first-day-of-week":"1","locale":"es-co","color":"primary"},on:{"input":function($event){item.menu = false}},model:{value:(item.values.model),callback:function ($$v) {_vm.$set(item.values, "model", $$v)},expression:"item.values.model"}})],1):_vm._e(),(item.type == 'select')?_c('v-select',{attrs:{"color":"primary","items":item.values.generos,"item-color":"primary","menu-props":{ maxHeight: '400' }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"tem-label"},[_vm._v(" "+_vm._s(item.values.label)+" ")])]},proxy:true}],null,true),model:{value:(item.values.model),callback:function ($$v) {_vm.$set(item.values, "model", $$v)},expression:"item.values.model"}}):_vm._e()],1)],1)}),(_vm.show)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',[_vm._v("Descargar para Android")]),_c('v-btn',{staticClass:"mt-4",attrs:{"fab":"","large":"","dark":"","color":"green"},on:{"click":function($event){return _vm.dowloadApp(
                          'https://play.google.com/store/apps/details?id=co.com.luxcultury&hl=es_CO&gl=US'
                        )}}},[_c('v-icon',[_vm._v("mdi-android")])],1)],1):_vm._e(),(_vm.show)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',[_vm._v("Descargar para IOS")]),_c('v-btn',{staticClass:"mt-4",attrs:{"fab":"","large":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.dowloadApp(
                          'https://apps.apple.com/co/app/luxcultury/id1458034498'
                        )}}},[_c('v-icon',[_vm._v("mdi-apple")])],1)],1):_vm._e()],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('router-link',{attrs:{"to":("/loginClientes/" + (_vm.$route.params.data))}},[_c('v-btn',{attrs:{"text":""}},[_vm._v("Volver")])],1),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.register()}}},[_vm._v("Registrar")])],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","right":"","color":_vm.color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.alert)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false;
        _vm.alert = '';}}},[_vm._v("X")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }